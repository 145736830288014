import React, { useCallback, useEffect, useRef } from 'react'
import useClickOutside from '../../../hooks/useClickOutside'
import {
  Close,
  Container,
  FullscreenWrap,
  PopupHeader,
  PopupTitle,
  PopupBody,
  PopupFooter,
} from './Popup.styles'
import { Icon } from '../Icon/Icon'
import { onKeyDownHandler } from '@/methods/keyboardAccessibility'

interface PopupProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  closeIconHidden?: boolean
  clickOutsideEnabled?: boolean
  title?: string
  footer?: React.ReactNode
  children: React.ReactNode
  handleVisibility: (boolean) => void
  visibility: boolean
  closeIcon?: React.ReactNode

  //FullscreenWrapProps {
  wrapBgColor?: string
  zIndex?: string
  //ContainerProps {
  width?: string
  height?: string
  margin?: string
  color?: string
  bgColor?: string
  border?: string
  borderRadius?: string
  mobileWidth?: string
  mobileHeight?: string
  mobileMargin?: string
  mobilePadding?: string
  dividingBorder?: string
  fontSize?: string
  //CloseProps
  closeTop?: string
  closeRight?: string
  closeLeft?: string
  closeBottom?: string
  closeCursor?: string
  //HeaderProps
  headerColor?: string
  headerBgColor?: string
}

export const Popup = ({
  children,
  title,
  footer,
  closeIconHidden,
  clickOutsideEnabled,
  handleVisibility,
  visibility,
  closeIcon,
  wrapBgColor,
  zIndex,
  width,
  height,
  margin,
  color,
  bgColor,
  border,
  dividingBorder,
  borderRadius,
  mobileWidth,
  mobileHeight,
  mobileMargin,
  mobilePadding,
  fontSize,
  closeTop,
  closeRight,
  closeLeft,
  closeBottom,
  closeCursor,
  headerColor,
  headerBgColor,
}: PopupProps) => {
  const popup = useRef(null)
  const closeButton = useRef(null)

  const close = useCallback(() => handleVisibility(false), [])
  useClickOutside(popup, clickOutsideEnabled ? close : undefined)

  useEffect(() => {
    if (closeButton.current) {
      closeButton?.current.focus()
    }
  }, [visibility])

  console.log('mobileHeight', mobileHeight)
  return visibility ? (
    <FullscreenWrap bgColor={wrapBgColor} zIndex={zIndex}>
      <Container
        ref={popup}
        tabIndex={-1}
        width={width}
        height={height}
        margin={margin}
        color={color}
        bgColor={bgColor}
        border={border}
        borderRadius={borderRadius}
        mobileWidth={mobileWidth}
        mobileHeight={mobileHeight}
        mobileMargin={mobileMargin}
        mobilePadding={mobilePadding}
        fontSize={fontSize}
      >
        {!closeIconHidden && (
          <Close
            tabIndex={0}
            onClick={() => handleVisibility(false)}
            onKeyDown={(event: KeyboardEvent) =>
              onKeyDownHandler(event, () => handleVisibility(false))
            }
            top={closeTop}
            right={closeRight}
            left={closeLeft}
            bottom={closeBottom}
            cursor={closeCursor}
            ref={closeButton}
          >
            {closeIcon ? closeIcon : <Icon size="24px" type="x-mark" />}
          </Close>
        )}
        {title && (
          <PopupHeader
            color={headerColor}
            bgColor={headerBgColor}
            border={dividingBorder}
            fontSize={fontSize}
            borderRadius={borderRadius}
          >
            <PopupTitle>{title}</PopupTitle>
          </PopupHeader>
        )}

        {children && (
          <PopupBody
            padding={
              !closeIconHidden && !title?.length ? '45px 30px 30px' : undefined
            }
          >
            {children}
          </PopupBody>
        )}

        {footer && <PopupFooter border={dividingBorder}>{footer}</PopupFooter>}
      </Container>
    </FullscreenWrap>
  ) : (
    <></>
  )
}
