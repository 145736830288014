import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import {
  Button,
  Header,
  Icon,
  LoadingSpinner,
  PdfViewer,
} from '@components/Generic'
import { observer } from 'mobx-react'
import { StoreContext } from '../App'

import {
  ButtonText,
  CheckboxForm,
  ZoomControlWrap,
} from '@styles/generic.styles'
import {
  ContentContainerBottomFixed,
  ContentToRight,
} from '@styles/layout.styles'
import { getViewerHeight } from '@styles/utils'
import { DownloadDocumentButton } from '@/components/DownloadDocumentButton'
import { actionReport } from '@/methods/actionReport'
import { axiosInstance } from '@/methods/axiosConfig'
import { devLog } from '@/methods/devLog'
import { hideDownloadButton } from '@/methods/hideDownloadButton'
import { onKeyDownHandler } from '@/methods/keyboardAccessibility'

interface SignDocumentSingleProps {
  signed?: boolean
  setSigned?: (value: boolean) => void
  signedClause?: boolean
  setSignedClause?: (value: boolean) => void
  doc?: {
    url: string
    id: string
  }
  handleSign: (docId: string) => Promise<boolean>
  btnLoading?: boolean
  setContainerHeight?: (value: string) => void
  showSubHeader?: boolean
  trans: {
    header: string
    subHeader?: string
    checkboxLabel: string
    checkboxClauseLabel?: string
    proceed: string
  }
}

export const SignDocumentSingle: FC<SignDocumentSingleProps> = observer(
  ({
    signed,
    setSigned,
    signedClause,
    setSignedClause,
    doc,
    handleSign,
    btnLoading,
    setContainerHeight,
    showSubHeader,
    trans,
  }) => {
    const store = useContext(StoreContext)
    const { screenSize } = store.AppState
    const { theme } = store.InterfaceState
    const { context, currentPageIndex } = store.ScenarioState
    const documentNeedsScrolling = context?.forceScrollingBeforeDocumentAccept

    const [documentScrolled, setDocumentScrolled] = useState(
      !documentNeedsScrolling
    )

    const zoomButtonProps = {
      ...theme.pdfViewer.zoomButton,
      borderRadius: '50%',
      width: '44px',
      height: '44px',
      widthMobile: '44px',
      heightMobile: '44px',
      paddingMobile: '0px',
      padding: '0px',
    }
    const [shouldZoomIn, setShouldZoomIn] = useState(false)
    const [shouldZoomOut, setShouldZoomOut] = useState(false)

    const headerRef = useRef(null)
    const footerRef = useRef(null)
    const [mobileVersion, setMobileVersion] = useState(false)
    const downloadButtonDisabled = hideDownloadButton()

    useEffect(() => {
      if (screenSize.width && screenSize.width <= 980) {
        setMobileVersion(true)
        setContainerHeight(
          `calc(${window.innerHeight}px - ${footerRef.current.offsetHeight}px)`
        )
      }
    }, [screenSize])

    const handleCheckboxChange = () => {
      setSigned(!signed)
    }

    const handleCheckboxClauseChange = () => {
      setSignedClause(!signedClause)
    }

    // audit logs
    useEffect(() => {
      if (signed === true) {
        actionReport({
          type: 'event.onboarding-web.agreement.AGREEMENT_ACKNOWLEDGED',
          payload: {
            documentId: doc?.id,
          },
        })
      }
    }, [signed])

    useEffect(() => {
      if (signedClause === true) {
        actionReport({
          type: 'event.onboarding-web.agreement.SANTANDER_CLAUSE_CONFIRMED',
          payload: {
            documentId: doc?.id,
          },
        })
      }
    }, [signedClause])

    useEffect(() => {
      if (documentScrolled === true) {
        actionReport({
          type: 'event.onboarding-web.agreement.AGREEMENT_SCROLLED_TO_THE_END',
          payload: {},
        })
      }
    }, [documentScrolled])

    const handleProceed = async () => {
      actionReport({
        type: 'event.onboarding-web.agreement.AGREEMENT_CONFIRMED',
        payload: {},
      })

      const isSuccess = await handleSign(doc?.id)

      if (!isSuccess) {
        return
      }

      store.ScenarioState.setCurrentPage(currentPageIndex + 1)
    }

    const [pdfForDownload, setPdfForDownload] = useState<Blob>(null)

    const fetchBlob = async () => {
      if (doc?.id) {
        try {
          const res = await axiosInstance.get<{ signedUrl: string }>(
            `/electronic-id/pdf-document-presigned`,
            {
              withCredentials: true,
              params: {
                fileId: doc?.id,
              },
            }
          )

          const { data } = await axiosInstance.get<Blob>(res.data?.signedUrl, {
            responseType: 'blob',
            withCredentials: false,
          })

          if (data) {
            setPdfForDownload(data)
          }
        } catch (error) {
          devLog('Error downloading file', error)
        }
      }
    }

    useEffect(() => {
      void fetchBlob()
    }, [doc])

    return (
      <>
        <Header
          {...theme.header}
          fontFamily={theme.globals.fontFamilyHeadline}
          flexSpaceBetween
          textAlign="left"
        >
          <span ref={headerRef}>
            {trans.header}
            {showSubHeader && <span>{trans.subHeader}</span>}
          </span>
          <ZoomControlWrap>
            <Button onClick={() => setShouldZoomOut(true)} {...zoomButtonProps}>
              <Icon
                {...theme.pdfViewer.zoomButton}
                type="zoom-out"
                size="30px"
              />
            </Button>

            <Button onClick={() => setShouldZoomIn(true)} {...zoomButtonProps}>
              <Icon
                {...theme.pdfViewer.zoomButton}
                type="zoom-in"
                size="30px"
              />
            </Button>
          </ZoomControlWrap>
        </Header>

        <PdfViewer
          file={doc?.url}
          {...theme.pdfViewer}
          viewerWidth="100%"
          containerHeight={
            mobileVersion
              ? getViewerHeight(
                  screenSize,
                  headerRef.current.offsetHeight,
                  footerRef.current.offsetHeight
                )
              : getViewerHeight(screenSize)
          }
          viewerHeight="100%"
          initialScale={1}
          onDocumentLastPage={() => setDocumentScrolled(true)}
          hideZoomControls
          controls={{
            shouldZoomIn,
            setShouldZoomIn,
            shouldZoomOut,
            setShouldZoomOut,
          }}
        />

        <ContentContainerBottomFixed ref={footerRef}>
          <CheckboxForm
            tabIndex={0}
            onKeyDown={(event) =>
              onKeyDownHandler(event, () => handleCheckboxChange())
            }
          >
            <input
              type="checkbox"
              name="sign"
              value={signed.toString()}
              checked={signed}
              id="sign"
              onChange={handleCheckboxChange}
            />
            <label className="label-text" htmlFor="sign">
              {trans.checkboxLabel}
            </label>
          </CheckboxForm>
          {setSignedClause && (
            <CheckboxForm
              tabIndex={0}
              onKeyDown={(event) =>
                onKeyDownHandler(event, () => handleCheckboxClauseChange())
              }
            >
              <input
                type="checkbox"
                name="signClause"
                value={signedClause.toString()}
                checked={signedClause}
                id="signClause"
                onChange={handleCheckboxClauseChange}
              />
              <label className="label-text" htmlFor="signClause">
                {trans.checkboxClauseLabel}
              </label>
            </CheckboxForm>
          )}
          <ContentToRight>
            {!downloadButtonDisabled && (
              <DownloadDocumentButton blobDoc={pdfForDownload} />
            )}

            <Button
              onClick={handleProceed}
              {...theme.button}
              disabled={
                !signed ||
                (setSignedClause && !signedClause) ||
                !documentScrolled ||
                !doc?.url
              }
              minWidth="224px"
              paddingMobile="14px 40px"
              id="page-submit"
            >
              {btnLoading ? (
                <LoadingSpinner
                  width="16px"
                  {...theme.button.loadingSpinner}
                  padding="0 0"
                  thickness={2}
                />
              ) : (
                <ButtonText>{trans.proceed}</ButtonText>
              )}
            </Button>
          </ContentToRight>
        </ContentContainerBottomFixed>
      </>
    )
  }
)
