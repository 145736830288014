import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { components } from 'react-select'
import { SelectInput, SelectOption } from '../SelectInput/SelectInput'
import { countriesList } from './countriesList'
import { externalList } from './countriesListExternal'

import {
  Container,
  CountryFlag,
  CountryItemInner,
} from './CountrySelect.styles'

const { Option, SingleValue } = components

interface CountrySelectProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  value: null | SelectOption
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  className?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (e: any) => void
  borderRadius?: string
  borderWidth?: string
  color?: string
  colorDisabled?: string
  backgroundColor?: string
  borderColorFocus?: string
  colorFocus?: string
  backgroundColorFocus?: string
  colorSelected?: string
  backgroundColorSelected?: string
  menuMarginTop?: string
  menuBorderWidth?: string
  indicatorColorFocus?: string
  indicatorColorHover?: string
  danger?: string
  dangerLight?: string
  autoFocus?: boolean
  defaultMenuIsOpen?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  externalList?: any[]
  placeholder?: string
  borderColor?: string
}

function getExternalFlag(countryCode: string) {
  const country = countriesList.find((country) => country.value == countryCode)
  return country?.icon || ''
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CountrySingleValue = (props: any) => (
  <SingleValue {...props}>
    <CountryItemInner>
      {externalList
        ? props.data.icon && (
            <CountryFlag>
              <LazyLoadImage
                alt={props.data.value}
                src={getExternalFlag(props.data.value)}
              />
            </CountryFlag>
          )
        : props.data.icon && (
            <CountryFlag>
              <LazyLoadImage alt={props.data.value} src={props.data.icon} />
            </CountryFlag>
          )}
      <span>{props.data.label}</span>
    </CountryItemInner>
  </SingleValue>
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CountryOption = (props: any) => (
  <Option {...props}>
    <CountryItemInner>
      {externalList
        ? props.data.icon && (
            <CountryFlag>
              <LazyLoadImage
                alt={props.data.value}
                src={getExternalFlag(props.data.value)}
              />
            </CountryFlag>
          )
        : props.data.icon && (
            <CountryFlag>
              <LazyLoadImage alt={props.data.value} src={props.data.icon} />
            </CountryFlag>
          )}
      <span>{props.data.label}</span>
    </CountryItemInner>
  </Option>
)

export const CountrySelect = (props: CountrySelectProps) => {
  const [selectValue, setSelectValue] = useState(null)

  function handleChange(value) {
    setSelectValue(value)
    props.onChange(value)
  }

  useEffect(() => setSelectValue(props.value), [props.value])

  return (
    <Container>
      <SelectInput
        options={props.externalList ? props.externalList : countriesList}
        value={selectValue}
        onChange={handleChange}
        isClearable={true}
        components={{ SingleValue: CountrySingleValue, Option: CountryOption }}
        className={props.className ? props.className : ''}
        borderRadius={props.borderRadius ? props.borderRadius : '0'}
        borderWidth={props.borderWidth}
        color={props.color}
        colorDisabled={props.colorDisabled}
        borderColor={props.borderColor}
        backgroundColor={props.backgroundColor}
        borderColorFocus={props.borderColorFocus}
        colorFocus={props.colorFocus}
        backgroundColorFocus={props.backgroundColorFocus}
        colorSelected={props.colorSelected}
        backgroundColorSelected={props.backgroundColorSelected}
        menuMarginTop={props.menuMarginTop}
        menuBorderWidth={props.menuBorderWidth}
        indicatorColorFocus={props.indicatorColorFocus}
        indicatorColorHover={props.indicatorColorHover}
        danger={props.danger ? props.danger : 'red'}
        dangerLight={props.dangerLight ? props.dangerLight : 'purple'}
        autoFocus={props.autoFocus ? props.autoFocus : false}
        defaultMenuIsOpen={
          props.defaultMenuIsOpen ? props.defaultMenuIsOpen : false
        }
        placeholder={
          props.placeholder ? props.placeholder : 'Select your country'
        }
      />
    </Container>
  )
}
