import styled, { createGlobalStyle } from 'styled-components'

export const EIDStyle = createGlobalStyle`
  body {
    #overlay {
      padding: 0;
      margin: 0;

      .eid-notification {
        overflow: auto;
        position: fixed;
        top: 0px;
        bottom: 0;
        left: 0px;
        right: 0;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        z-index: 1;
        backdrop-filter: blur(8px);
        box-shadow: none;
        max-height: unset;
        max-width: unset;
        width: 100%;
        height: 100%;
      }

      .eid-notification-centered-content {
        background-color: rgb(var(--eid-light-background-rgb));
        box-sizing: border-box;
        height: 90%;

        max-height: 380px;
        max-width: 540px;
        width: 90%;

        @media (max-width: 980px) {
          max-height: 90%;
        }
      }

      .eid-ui-canvas *,
      .eid-overlay * {
        font-family: ${(props) =>
          props.fontFamily ? `${props.fontFamily} !important` : ''};
      }

      #video {
        height: 540px;

        @media (max-width: 980px) {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: calc(100vh - 100px);
        }
      }

      .eid-title,
      .eid-h1,
      .eid-h2,
      .eid-h3,
      .eid-h4 {
        font-family: ${(props) =>
          props.fontFamilyHeadline
            ? `${props.fontFamilyHeadline} !important`
            : ''};
      }

      .btn--big {
        margin-top: 20px;
      }

      .eid-a {
        text-align: center;
        color: ${(props) =>
          props.button.color
            ? `${props.button.color} !important`
            : '#58FDD2 !important'};

        &:focus-visible {
          // outline: -webkit-focus-ring-color auto 1px;
          outline: none;
          box-shadow: 0 0 0 3px hsla(220, 100%, 50%, 50%);
          transition: box-shadow 0.3s ease;
        }
      }

      .eid-phone-update {
        .eid-a {
          text-align: center;
          color: ${(props) =>
            props.videoIdPhoneChangeButton.color
              ? `${props.videoIdPhoneChangeButton.color} !important`
              : 'rgba(42, 0, 98, 1) !important'};

          &:focus-visible {
            // outline: -webkit-focus-ring-color auto 1px;
            outline: none;
            box-shadow: 0 0 0 3px hsla(220, 100%, 50%, 50%);
            transition: box-shadow 0.3s ease;
          }
        }
      }

      .eid-button {
        color: ${(props) =>
          props.button.color ? `${props.button.color}` : '#ffffff !important'};

        &:focus-visible {
          // outline: -webkit-focus-ring-color auto 1px;
          outline: none;
          box-shadow: 0 0 0 3px hsla(220, 100%, 50%, 50%);
          transition: box-shadow 0.3s ease;
        }
      }

      .eid-button-close {
        margin-left: 16px;
      }

      .eid-button-container {
        display: flex;
        justify-content: space-between;
        gap: 16px;

        & > .eid-button-close {
          margin-left: 0;
        }
      }

      .eid-textbox:focus {
        outline: none !important;
      }

      .eid-textbox-character:focus {
        outline: 2px solid ${(props) =>
          props.otpInput
            ? `${props.otpInput.focusColor} !important`
            : 'orange'};
      }

      .eid-va-center {
        margin-top: 10px;
      }

      .eid-button-primary,
      .eid-call-to-action,
      .eid-call-to-action-3 {
        background-color: ${(props) =>
          props.button.bgColor
            ? `${props.button.bgColor} !important`
            : '#58FDD2 !important'};
        border: ${(props) =>
          props.button.border
            ? `${props.button.border} !important`
            : '#58FDD2 !important'};
        color: ${(props) =>
          props.button.color ? `${props.button.color}` : '#ffffff !important'};
        border-radius: ${(props) =>
          props.button.borderRadius
            ? `${props.button.borderRadius} !important`
            : '66px !important'};

        &:hover {
          background-color: ${(props) =>
            props.button.bgColorHover
              ? `${props.button.bgColorHover} !important`
              : '#280B60 !important'};
          border: ${(props) =>
            props.button.border
              ? `${props.button.border} !important`
              : '#280B60 !important'};
          color: ${(props) =>
            props.button.color
              ? `${props.button.color}`
              : '#58FDD2 !important'};
        }

        &:disabled,
        &[disabled] {
          background-color: ${(props) =>
            props.button.bgColorDisabled
              ? `${props.button.bgColorDisabled} !important`
              : '#9dafe5'};
          border: ${(props) =>
            props.button.borderDisabled
              ? `${props.button.borderDisabled} !important`
              : '1px solid #9dafe5'};
          color: ${(props) =>
            props.button.colorDisabled
              ? `${props.button.colorDisabled} !important`
              : '#dee6ff'};
        }

        &:focus-visible {
          // outline: -webkit-focus-ring-color auto 1px;
          outline: none;
          box-shadow: 0 0 0 3px hsla(220, 100%, 50%, 50%);
          transition: box-shadow 0.3s ease;
        }

        .eid-button {
          font-size: 14px !important;
        }

        .eid-choices .choices__inner {
          border-radius: 5px !important;
        }
      }

      .eid-call-to-action-3 {
        padding: 12px 36px;
      }

      .eid-hologram-media {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
        padding: 20px 0 !important;
        width: auto !important;

        .eid-title {
          font-size: 1.2em !important;
          margin: 0 0 20px !important;
        }

        .eid-h7 {
          font-size: 1em !important;
          margin: 0 0 20px !important;
        }

        .eid-image {
          height: 5em !important;
          width: 5em !important;
          margin-bottom: 20px !important;
        }

        .eid-description {
          font-size: 0.9em !important;
          margin: 0 0 30px !important;
        }

        .eid-call-to-action-3 {
          display: block !important;
          text-decoration: none !important;
          padding: ${(props) =>
            props.button.padding
              ? `${props.button.padding} !important`
              : '12px 36px'};
          font-weight: ${(props) =>
            props.button.fontWeight
              ? `${props.button.fontWeight} !important`
              : '600'};
          font-size: ${(props) =>
            props.button.fontSize
              ? `${props.button.fontSize} !important`
              : '13px'};
          border-radius: ${(props) =>
            props.button.borderRadius
              ? `${props.button.borderRadius} !important`
              : '0px'};

          &:focus-visible {
            // outline: -webkit-focus-ring-color auto 1px;
            outline: none;
            box-shadow: 0 0 0 3px hsla(220, 100%, 50%, 50%);
            transition: box-shadow 0.3s ease;
          }
        }
      }

      .iti__country {
        margin: 3px;
        &:focus {
          // outline: -webkit-focus-ring-color auto 1px;
          outline: none;
          box-shadow: 0 0 0 3px hsla(220, 100%, 50%, 50%);
          transition: box-shadow 0.3s ease;
        }
      }
    }
  }
`

export const VideoId = styled.div`
  padding: 0;
  margin: 0;

  .eid-notification {
    overflow: auto;
  }

  .eid-ui-canvas *,
  .eid-overlay * {
    font-family: ${(props) =>
      props.fontFamily ? `${props.fontFamily} !important` : ''};
  }

  #video {
    height: 540px;

    @media (max-width: 980px) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100vh - 100px);
    }
  }

  .eid-title,
  .eid-h1,
  .eid-h2,
  .eid-h3,
  .eid-h4 {
    font-family: ${(props) =>
      props.fontFamilyHeadline ? `${props.fontFamilyHeadline} !important` : ''};
  }

  .btn--big {
    margin-top: 20px;
  }

  .eid-a {
    text-align: center;
    color: ${(props) =>
      props.color ? `${props.color} !important` : '#58FDD2 !important'};

    &:focus-visible {
      // outline: -webkit-focus-ring-color auto 1px;
      outline: none;
      box-shadow: 0 0 0 3px hsla(220, 100%, 50%, 50%);
      transition: box-shadow 0.3s ease;
    }
  }

  .eid-phone-update {
    .eid-a {
      text-align: center;
      color: ${(props) =>
        props.videoIdPhoneChangeButton.color
          ? `${props.videoIdPhoneChangeButton.color} !important`
          : 'rgba(42, 0, 98, 1) !important'};

      &:focus-visible {
        // outline: -webkit-focus-ring-color auto 1px;
        outline: none;
        box-shadow: 0 0 0 3px hsla(220, 100%, 50%, 50%);
        transition: box-shadow 0.3s ease;
      }
    }
  }

  .eid-button {
    color: ${(props) =>
      props.color ? `${props.color}` : '#ffffff !important'};

    &:focus-visible {
      // outline: -webkit-focus-ring-color auto 1px;
      outline: none;
      box-shadow: 0 0 0 3px hsla(220, 100%, 50%, 50%);
      transition: box-shadow 0.3s ease;
    }
  }

  .eid-textbox:focus {
    outline: none !important;
  }

  .eid-textbox-character:focus {
    outline: 2px solid
      ${(props) =>
        props.otpInput ? `${props.otpInput.focusColor} !important` : 'orange'};
  }

  .eid-button-close {
    margin-left: 16px;
  }

  .eid-button-container {
    display: flex;
    justify-content: space-between;
    gap: 16px;

    & > .eid-button-close {
      margin-left: 0;
    }
  }

  .eid-button-primary,
  .eid-call-to-action,
  .eid-call-to-action-3 {
    background-color: ${(props) =>
      props.bgColor ? `${props.bgColor} !important` : '#58FDD2 !important'};
    border: ${(props) =>
      props.border ? `${props.border} !important` : '#58FDD2 !important'};
    color: ${(props) =>
      props.color ? `${props.color}` : '#ffffff !important'};
    border-radius: ${(props) =>
      props.borderRadius
        ? `${props.borderRadius} !important`
        : '66px !important'};

    &:hover {
      background-color: ${(props) =>
        props.bgColorHover
          ? `${props.bgColorHover} !important`
          : '#280B60 !important'};
      border: ${(props) =>
        props.border ? `${props.border} !important` : '#280B60 !important'};
      color: ${(props) =>
        props.color ? `${props.color}` : '#58FDD2 !important'};
    }

    &:disabled,
    &[disabled] {
      background-color: ${(props) =>
        props.bgColorDisabled
          ? `${props.bgColorDisabled} !important`
          : '#9dafe5'};
      border: ${(props) =>
        props.borderDisabled
          ? `${props.borderDisabled} !important`
          : '1px solid #9dafe5'};
      color: ${(props) =>
        props.colorDisabled ? `${props.colorDisabled} !important` : '#dee6ff'};
    }

    &:focus-visible {
      // outline: -webkit-focus-ring-color auto 1px;
      outline: none;
      box-shadow: 0 0 0 3px hsla(220, 100%, 50%, 50%);
      transition: box-shadow 0.3s ease;
    }

    .eid-button {
      font-size: 14px !important;
    }

    .eid-choices .choices__inner {
      border-radius: 5px !important;
    }
  }

  p + .eid-call-to-action-2 {
    text-align: center !important;
    width: 150px !important;
    text-decoration: ${(props) =>
      props.videoIdCallToActionButton.textDecoration
        ? props.videoIdCallToActionButton.textDecoration
        : 'underline !important'};
    color: ${(props) =>
      props.videoIdCallToActionButton.color
        ? `${props.videoIdCallToActionButton.color} !important`
        : 'rgba(42, 0, 98, 1) !important'};
    border: ${(props) =>
      props.videoIdCallToActionButton.border
        ? `${props.videoIdCallToActionButton.border} !important`
        : '1px solid rgba(65, 255, 209, 1) !important'};
    background-color: ${(props) =>
      props.videoIdCallToActionButton.bgColor
        ? `${props.videoIdCallToActionButton.bgColor} !important`
        : 'rgba(65, 255, 209, 1) !important'};
    margin: ${(props) =>
      props.videoIdCallToActionButton.margin
        ? `${props.videoIdCallToActionButton.margin} !important`
        : '0 !important'};
    padding: ${(props) =>
      props.videoIdCallToActionButton.padding
        ? `${props.videoIdCallToActionButton.padding} !important`
        : '10px 34px !important'};
    border-radius: ${(props) =>
      props.videoIdCallToActionButton.borderRadius
        ? `${props.videoIdCallToActionButton.borderRadius} !important`
        : '2px !important'};
    font-size: ${(props) =>
      props.videoIdCallToActionButton.fontSize
        ? `${props.videoIdCallToActionButton.fontSize} !important`
        : '13px !important'};
    font-weight: ${(props) =>
      props.videoIdCallToActionButton.fontWeight
        ? `${props.videoIdCallToActionButton.fontWeight} !important`
        : '600 !important'};
    box-shadow: ${(props) =>
      props.videoIdCallToActionButton.boxShadow
        ? `${props.videoIdCallToActionButton.boxShadow} !important`
        : 'none !important'};

    &:hover {
      color: ${(props) =>
        props.videoIdCallToActionButton.colorHover
          ? `${props.videoIdCallToActionButton.colorHover} !important`
          : 'rgb(255, 255, 255) !important'};
      background-color: ${(props) =>
        props.videoIdCallToActionButton.bgColorHover
          ? `${props.videoIdCallToActionButton.bgColorHover} !important`
          : 'rgba(65, 255, 209, 1) !important'};
      border: ${(props) =>
        props.videoIdCallToActionButton.borderHover
          ? `${props.videoIdCallToActionButton.borderHover} !important`
          : '1px solid rgba(65, 255, 209, 1) !important'};
    }

    &:disabled {
      color: ${(props) =>
        props.videoIdCallToActionButton.colorDisabled
          ? `${props.videoIdCallToActionButton.colorDisabled} !important`
          : 'rgba(78, 179, 153, 1) !important'};
      background-color: ${(props) =>
        props.videoIdCallToActionButton.bgColorDisabled
          ? `${props.videoIdCallToActionButton.bgColorDisabled} !important`
          : 'rgba(196, 253, 239, 1) !important'};
      border: ${(props) =>
        props.videoIdCallToActionButton.borderDisabled
          ? `${props.videoIdCallToActionButton.borderDisabled} !important`
          : '1px solid rgba(196, 253, 239, 1) !important'};
    }

    &:focus-visible {
      // outline: -webkit-focus-ring-color auto 1px;
      outline: none;
      box-shadow: 0 0 0 3px hsla(220, 100%, 50%, 50%);
      transition: box-shadow 0.3s ease;
    }

    @media (max-width: 420px) {
      margin: 18px auto 0 auto !important;
    }
  }

  .eid-call-to-action-3 {
    padding: 12px 36px;
  }

  .eid-hologram-media {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 20px 0 !important;
    width: auto !important;

    .eid-title {
      font-size: 1.2em !important;
      margin: 0 0 20px !important;
    }

    .eid-h7 {
      font-size: 1em !important;
      margin: 0 0 20px !important;
    }

    .eid-image {
      height: 5em !important;
      width: 5em !important;
      margin-bottom: 20px !important;
    }

    .eid-description {
      font-size: 0.9em !important;
      margin: 0 0 30px !important;
    }

    .eid-call-to-action-3 {
      display: block !important;
      text-decoration: none !important;
      padding: ${(props) =>
        props.padding ? `${props.padding} !important` : '12px 36px'};
      font-weight: ${(props) =>
        props.fontWeight ? `${props.fontWeight} !important` : '600'};
      font-size: ${(props) =>
        props.fontSize ? `${props.fontSize} !important` : '13px'};
      border-radius: ${(props) =>
        props.borderRadius ? `${props.borderRadius} !important` : '0px'};
    }
  }

  .iti__country {
    margin: 3px;
    &:focus {
      // outline: -webkit-focus-ring-color auto 1px;
      outline: none;
      box-shadow: 0 0 0 3px hsla(220, 100%, 50%, 50%);
      transition: box-shadow 0.3s ease;
    }
  }
`

export const HideVideoIdModal = createGlobalStyle`
body{
  .eid-notification.eid-notification-modal.eid-animated.eid-animated-center-reveal {
    display: none !important;
  }
}
`
