import styled from 'styled-components'

export const CodeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Code = styled.div`
  border-radius: 2px;
  border: 2px solid var(--Grey, #ced4dc);
  width: 116px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #34353a;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 5.2px;
  padding-left: 5.2px;
`

export const AccordionWrapper = styled.div<{ isOpen?: boolean }>`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 20px;
`

export const AccordionTitle = styled.div<{ isOpen?: boolean }>`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  h3 {
    margin: 0;
    color: #34353a;
    font-size: 16px;
    font-weight: 700;
    line-height: 170%;
    font-family: ${(props) => props.fontFamily};
  }

  svg {
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 0.3s ease-in-out;
  }

  &:focus-visible {
    // outline: -webkit-focus-ring-color auto 1px;
    outline: none;
    box-shadow: 0 0 0 3px hsla(220, 100%, 50%, 50%);
    transition: box-shadow 0.3s ease;
  }
`

export const Accordion = styled.div<{ isOpen?: boolean }>`
  margin-top: ${({ isOpen }) => (isOpen ? '20px' : '0')};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};

  transition: height 0.3s ease-in-out, margin-top 0.3s ease-in-out;
  overflow-y: hidden;

  p {
    width: 100%;
    margin-bottom: 10px;
    font-weight: 300;
    line-height: 170%;
  }
`

export const CenteredSMSText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  p {
    color: #34353a;
    text-align: center;
    font-family: ${(props) => props.fontFamily};
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    margin: 0px;
    text-align: center;
  }
`
