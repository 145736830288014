/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, {
  CSSProperties,
  FC,
  memo,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Page } from 'react-pdf'
import { FixedSizeList as List } from 'react-window'
import { InnerWrap, PageContainer } from './PdfViewer.styles'
import { devLog } from '@/methods/devLog'
import { Theme } from '@/constants/themes/theme'

interface RowProps {
  index: number
  style: CSSProperties
}

interface PageRendererProps {
  pdfViewerTheme: Theme['pdfViewer']
  numPages: number
  searchText?: string
  pageClassName: string
  canvasWidth: number
  pageScale: number
  containerDimensions?: { width: number; height: number }
  viewerWidth?: string | number
  viewerHeight?: string | number
  onDocumentLastPage?: () => void
  setCurrentPageIndex?: (index: number) => void
  handlePageIndicatorVisibility?: () => void
  onFocusHandler?: (isFocused: boolean) => void
}

export const PageRenderer: FC<PageRendererProps> = memo(
  ({
    pdfViewerTheme,
    numPages,
    pageClassName,
    canvasWidth,
    pageScale,
    containerDimensions,
    viewerWidth,
    viewerHeight,
    onDocumentLastPage,
    setCurrentPageIndex,
    handlePageIndicatorVisibility,
    onFocusHandler,
  }) => {
    const {
      pageBackground,
      scrollTrackColor,
      scrollHandleColor,
      scrollRadius,
    } = pdfViewerTheme

    const scrollContainerRef = useRef<HTMLDivElement>(null)

    const [highestScrolledPage, setHighestScrolledPage] = useState(0)

    // Render function for react-window
    const ReactWindowPage = ({ index, style }: RowProps) => {
      const targetRef = useRef(null)

      useEffect(() => {
        const observer = new IntersectionObserver(
          ([entry]) => {
            console.log('OBSERVING', entry.isIntersecting, index + 1)

            if (entry.isIntersecting) {
              setCurrentPageIndex(index + 1)

              if (index + 1 > highestScrolledPage) {
                setHighestScrolledPage(index + 1)
              }
            }
          },
          {
            root: null, // Set to a parent container if needed, or leave `null` for the viewport
            threshold: 0.1, // Percentage of the element that needs to be visible
          }
        )

        if (targetRef.current) {
          observer.observe(targetRef.current)
        }

        return () => {
          if (targetRef.current) {
            observer.unobserve(targetRef.current)
          }
        }
      }, [])

      return (
        <PageContainer
          style={style}
          ref={targetRef}
          tabIndex={0}
          onFocus={() => onFocusHandler(true)}
          onBlur={() => onFocusHandler(false)}
        >
          <Page
            devicePixelRatio={Math.min(2, window.devicePixelRatio)}
            className={pageClassName}
            pageNumber={index + 1}
            canvasBackground={pageBackground}
            width={canvasWidth}
            scale={pageScale}
          />
        </PageContainer>
      )
    }

    const onScrollHandler = (e) => {
      handlePageIndicatorVisibility()

      console.log(
        'scrolling',
        scrollContainerRef?.current?.getBoundingClientRect(),
        // pageHeight * numPages,
        e.scrollOffset
      )

      highestScrolledPage === numPages && console.log('highestScrolledPage')

      highestScrolledPage === numPages &&
        onDocumentLastPage &&
        onDocumentLastPage()
    }

    return (
      <InnerWrap
        ref={scrollContainerRef}
        viewerWidth={viewerWidth}
        viewerHeight={viewerHeight}
        scrollTrackColor={scrollTrackColor}
        scrollHandleColor={scrollHandleColor}
        scrollRadius={scrollRadius}
      >
        <List
          height={containerDimensions.height}
          itemSize={containerDimensions.width * 1.414 * pageScale}
          itemCount={numPages}
          width={containerDimensions.width}
          onScroll={onScrollHandler}
          overscanCount={1}
        >
          {ReactWindowPage}
        </List>
      </InnerWrap>
    )
  }
)
