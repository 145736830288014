import styled, { createGlobalStyle } from 'styled-components'

export const InputStyle = createGlobalStyle`
  body{
    input[type="checkbox"] {
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer;
    }

    input:checked + .label-text:before {
      background-color: ${(props) =>
        props.checkboxColorActive ? props.checkboxColorActive : 'black'};
      border: ${(props) =>
        props.checkboxColorActive
          ? `${props.checkboxColorActive} 2px solid !important`
          : 'gray 2px solid !important'};
    }

    input:checked + .label-text:after {
      content: '';
      display: block;
      position: absolute;
      top: 2px !important;
      left: 6.5px;
      width: 6px;
      height: 9.5px;
      border: solid
        ${(props) =>
          props.container.bgColor ? `${props.container.bgColor}` : 'white'};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    .label-text {
      position: relative;
      display: flex;
    }

    .label-text:before {
      width: 20px;
      height: 20px;
      content:'';
      -webkit-appearance: none;
      appearance: none;
      background-color: transparent;
      border: ${(props) =>
        props.checkboxColor
          ? `${props.checkboxColor} 2px solid !important`
          : 'gray 2px solid !important'};
          border-radius: ${(props) =>
            props.checkboxBorderRadius ? `${props.checkboxBorderRadius}` : '0'};
      padding: 8px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 10px;
      top: 0px;
      box-sizing: border-box;

      &:focus-visible {
        // outline: -webkit-focus-ring-color auto 1px;
        outline: none;
        box-shadow: 0 0 0 3px hsla(220, 100%, 50%, 50%);
        transition: box-shadow 0.3s ease;
      }
    }
  }
`

export const TickAllStyle = styled.div<{
  padding: string
  margin: string
  minWidth: string
  width: string
  widthMobile: string
  height: string
  heightMobile: string
  fontSize: string
  fontSizeMobile: string
  fontWeight: string
  textDecoration: string
}>`
  box-sizing: border-box;
  padding: ${(props) => (props.padding ? props.padding : '20px 10px')};
  margin: ${(props) => (props.margin ? props.margin : '0')};
  min-width: ${(props) => (props.minWidth ? props.minWidth : 'auto')};
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '500')};
  text-decoration: ${(props) =>
    props.textDecoration ? props.textDecoration : 'none'};

  span {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  @media (max-width: 980px) {
    font-size: ${(props) =>
      props.fontSizeMobile ? props.fontSizeMobile : props.fontSize};
    width: ${(props) => (props.widthMobile ? props.widthMobile : '100%')};
    height: ${(props) => (props.heightMobile ? props.heightMobile : 'auto')};
    padding: ${(props) =>
      props.paddingMobile ? props.paddingMobile : '10px 15px'};
    margin: ${(props) => (props.marginMobile ? props.marginMobile : '0')};
  }
`
