import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

import styled from 'styled-components'

interface ContainerProps {
  viewerWidth?: string
  viewerHeight?: string
  border?: string
}

export const Container = styled.div.attrs({
  className: 'authflow-pdf-viewer-container',
})<ContainerProps>`
  display: inline-block;
  position: relative;
  width: ${(props) => (props.viewerWidth ? props.viewerWidth : '500px')};
  height: ${(props) => (props.viewerHeight ? props.viewerHeight : '700px')};
  overflow: hidden;
  box-sizing: border-box;
  padding: 0;
  border: ${(props) => (props.border ? props.border : 'none')};
  /* outline: ${(props) =>
    props.isFocused ? '-webkit-focus-ring-color auto 1px' : 'none'}; */

  outline: none;
  box-shadow: ${(props) =>
    props.isFocused
      ? '0 0 0 3px hsla(220, 100%, 50%, 50%) !important'
      : 'none'};
  transition: box-shadow 0.3s ease;
`

export const Navigation = styled.div.attrs({
  className: 'authflow-pdf-viewer-navigation',
})`
  z-index: 99999;
  position: absolute;
  left: 0px;
  bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`

interface NavigationInnerProps {
  color?: string
  bgColor?: string
  borderRadius?: string
  padding?: string
  boxShadow?: string
}

export const NavigationInner = styled.div.attrs({
  className: 'authflow-pdf-viewer-navigation-inner',
})<NavigationInnerProps>`
  display: flex;
  gap: 18px;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : 'rgba(99,99,99,0.8)'};
  color: ${(props) => (props.color ? props.color : 'white')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '8px'};
  box-sizing: border-box;
  padding: ${(props) => (props.padding ? props.padding : '10px 30px')};
  height: fit-content;
  width: fit-content;
`

interface NavigationPagesProps {
  borderColor?: string
}

export const NavigationPages = styled.div.attrs({
  className: 'authflow-pdf-viewer-navigation-pages',
})<NavigationPagesProps>`
  margin: 0 10px;
  display: flex;
  justify-content: center;
  gap: 10px;
  box-sizing: border-box;
  padding-right: 10px;
  border-right: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : 'gray')};
`

interface InnerWrapProps {
  viewerWidth: string
  viewerHeight: string
  scrollHandleColor?: string
  scrollTrackColor?: string
  scrollRadius?: string
}

const parse = (value: string | number) => {
  if (typeof value === 'string') {
    return value
  } else {
    return `${value}px`
  }
}

export const InnerWrap = styled.div.attrs({
  className: 'authflow-pdf-viewer-inner-wrap',
})<InnerWrapProps>`
  display: inline-block;
  position: relative;
  width: ${({ viewerWidth }) => (viewerWidth ? parse(viewerWidth) : '500px')};
  height: ${({ viewerHeight }) =>
    viewerHeight ? parse(viewerHeight) : '700px'};
  /* overflow: auto; */
  overflow-x: hidden;
  overflow-y: hidden;

  & > div {
    height: ${({ viewerHeight }) =>
      viewerHeight ? parse(viewerHeight) : '700px'} !important;
    overflow-y: scroll;
    scrollbar-gutter: stable;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: ${(props) =>
        props.scrollTrackColor ? props.scrollTrackColor : '#ebebeb'};
      border-radius: ${(props) =>
        props.scrollRadius ? props.scrollRadius : '10px'};
    }

    &::-webkit-scrollbar-thumb {
      background: ${(props) =>
        props.scrollHandleColor ? props.scrollHandleColor : '#6d6d6d'};
      border-radius: ${(props) =>
        props.scrollRadius ? props.scrollRadius : '10px'};
    }
  }
`

export const PageNavigationInner = styled(NavigationInner)`
  width: calc(100% - 32px);

  .range {
    width: 100%;
    margin: 0 16px;
  }
`

export const RangeContainer = styled.div`
  z-index: 99999;
  margin: 12px 0;
  height: calc(100% - 24px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
`

export const RangeThumb = styled.div<{
  thumbColor?: string
  thumbHoverColor?: string
  thumbFocusBorderColor?: string
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background-color: transparent;
  cursor: pointer;
  position: relative;

  & > div {
    width: 12px;
    height: 12px;
    background-color: ${({ thumbColor }) => thumbColor || '#6d6d6d'};
    border-radius: 50%;

    &:hover {
      background-color: ${({ thumbHoverColor }) =>
        thumbHoverColor || '#000000'};
    }
  }

  &:focus-visible {
    outline: none;

    & > div {
      /* outline: ${({ thumbFocusBorderColor }) =>
        `${thumbFocusBorderColor} auto 1px` ||
        '-webkit-focus-ring-color auto 1px'}; */
      outline: none;
      box-shadow: 0 0 0 3px hsla(220, 100%, 50%, 50%);
      transition: box-shadow 0.3s ease;
    }
  }
`

export const RangeTrack = styled.div<{
  trackColor?: string
  activeTrackColor?: string
}>`
  width: 4px;
  height: 100%;
  margin: auto 16px;
  background-color: ${({ bgColor }) => bgColor || '#ebebeb'};
  border-radius: 4px;
`

export const PageNumberIndicator = styled.div<{
  bgColor?: string
  color?: string
}>`
  height: 30px;
  margin: 12px 0;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 12px;
  right: 42px;

  background-color: ${({ bgColor }) => bgColor || '#000000B2'};
  color: ${({ color }) => color || '#FFFFFF'};
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;

  span {
    margin: 0 2px;
  }
`

export const PageContainer = styled.div``
