import {
  Button,
  ContentContainer,
  CountrySelect,
  Header,
  Icon,
  LoadingSpinner,
  TextInput,
} from '../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../components/App'
import { actionReport } from '../methods/actionReport'
import { axiosInstance } from '../methods/axiosConfig'
import {
  ButtonImgWrap,
  ButtonText,
  CountryWrap,
  PlaceOfBirthWrap,
} from '../styles/generic.styles'
import {
  ContentContainerBottomFixed,
  ContentContainerBottomLeft,
} from '../styles/layout.styles'

export const PlaceOfBirthPage = observer(() => {
  const store = useContext(StoreContext)
  const { pageWidth } = store.AppState

  const { theme } = store.InterfaceState
  const { currentPageIndex, currentScenarioId, context } = store.ScenarioState
  const { placeOfBirthPage: trans } = store.TranslationsState.translations

  const [placeOfBirth, setPlaceOfBirth] = useState('')
  const [country, setCountry] = useState(null)
  const [apiCountries, setApiCountries] = useState([])
  const [btnLoading, setBtnLoading] = useState(false)
  const [mobileVersion, setMobileVersion] = useState(false)

  const getCountries = async () => {
    try {
      const apiUrl = process.env.WEB_API_URL

      const res = await axiosInstance.get(`${apiUrl}/electronic-id/countries`, {
        withCredentials: true,
      })

      const countriesArray = res.data
        ? Object.keys(res.data).map((countryCode) => {
            const country = res.data[countryCode]
            return {
              value: countryCode,
              label: `${country.countryName} | ${country.countryNameEN}`,
              icon: countryCode,
              countryCode: countryCode,
              ...country,
            }
          })
        : []

      res.data && setApiCountries(countriesArray)
    } catch (e) {
      throw e
    }
  }

  const handleProceed = async () => {
    if (currentScenarioId !== 'localTests') {
      setBtnLoading(true)

      const apiUrl = process.env.WEB_API_URL

      await axiosInstance.post(
        `${apiUrl}/electronic-id/end-user-input`,
        {
          countryOfBirth: country.value,
          placeOfBirth: placeOfBirth,
        },
        {
          withCredentials: true,
        }
      )
      setBtnLoading(false)
    }

    store.ScenarioState.setCurrentPage(currentPageIndex + 1)
  }

  const handleCountry = (country, report?) => {
    if (country) {
      const countryApiObj = apiCountries.find(
        (apiObj) => apiObj.countryCode === country.value
      )

      setCountry(countryApiObj)
    } else {
      setCountry(null)
    }
  }

  const handleOnBlur = () => {
    if (placeOfBirth) {
      actionReport({
        type: 'event.onboarding-web.eidv.SET_END_USER_INPUT',
        payload: {
          countryOfBirth: country.value,
          placeOfBirth: placeOfBirth,
        },
      })
    }
  }

  useEffect(() => {
    if (apiCountries.length === 1) {
      handleCountry(apiCountries[0], false)
    }

    if (currentScenarioId === 'localTests') {
      const countryApiObj = apiCountries.find(
        (apiObj) => apiObj.countryCode === 'PL'
      )

      setCountry(countryApiObj)
    }
  }, [apiCountries])

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setMobileVersion(true)
    }
  }, [pageWidth])

  useEffect(() => {
    if (!context?.askUserForPlaceAndCountryOfBirth) {
      store.ScenarioState.setCurrentPage(currentPageIndex + 1)
    }

    getCountries()
  }, [])

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
    >
      <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
        {trans.header}
      </Header>
      <CountryWrap>
        <span>{trans.countryOfBirth}</span>
        {mobileVersion ? (
          <>
            <CountrySelect
              externalList={apiCountries}
              value={country}
              onChange={(value) => handleCountry(value)}
              autoFocus={true}
              defaultMenuIsOpen={false}
              placeholder={trans.inputPlaceholder}
              {...theme.selectInput}
            />
          </>
        ) : (
          <>
            <CountrySelect
              externalList={apiCountries}
              value={country}
              onChange={(value) => handleCountry(value)}
              autoFocus={false}
              defaultMenuIsOpen={false}
              placeholder={trans.inputPlaceholder}
              {...theme.selectInput}
            />
          </>
        )}
      </CountryWrap>

      <PlaceOfBirthWrap>
        <span>{trans.placeOfBirth}</span>
        <TextInput
          type="text"
          value={placeOfBirth}
          onChange={(value) => {
            setPlaceOfBirth(value)
          }}
          onBlur={handleOnBlur}
          disabled={!country}
          {...theme.textInput}
          id="place-of-birth-input"
        />
      </PlaceOfBirthWrap>

      <ContentContainerBottomFixed>
        {!mobileVersion ? <ContentContainerBottomLeft /> : <></>}
        <Button
          onClick={() => handleProceed()}
          disabled={!(placeOfBirth && placeOfBirth.length)}
          {...theme.button}
          width="164px"
          paddingMobile="14px 40px"
          id="page-submit"
        >
          {btnLoading ? (
            <LoadingSpinner
              width="16px"
              {...theme.button.loadingSpinner}
              padding="0 0"
              thickness={2}
            />
          ) : (
            <>
              <ButtonImgWrap>
                <Icon size="18px" type="checkmark" />
              </ButtonImgWrap>
              <ButtonText>{trans.proceed}</ButtonText>
            </>
          )}
        </Button>
      </ContentContainerBottomFixed>
    </ContentContainer>
  )
})
